@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-VariableFont_wght.ttf");
}

* {
  font-family: "Montserrat";
}

.ant-btn {
  box-shadow: none;
}

.ant-popover-inner {
  max-height: 60vh;
  overflow-y: auto;
  padding: 0;
}

.ant-popover-inner ul {
  padding: 0px;
  margin: 0px;
}

.ant-popover-inner ul li {
  list-style-type: none;
  cursor: pointer;
  transition: 0.3s;
  padding: 5px;
  margin: 2px 0;
  border-radius: 4px;
}

.icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
  border-radius: 4px;
  cursor: pointer;
}

.img-wrap {
  width: 100%;
  height: 128px;
  border: 1px solid #cacaca;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
}

.img-wrap .icoon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 96px;
  color: #cacaca;
  cursor: pointer;
}

.img-wrap input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.img-wrap img {
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-upload,
.ant-upload-select,
.ant-upload-list,
.ant-upload-list-item-container {
  width: 100% !important;
}
